<template>
  <b-card-body>
    <template v-if="!choose_phone_number">
      <b-card-title>Instagram Channel</b-card-title>
      <b-card-sub-title>Instagram is one of the most popular social media in the world. Instagram is part of Meta company so they requires a Facebook account.</b-card-sub-title>
        <el-divider/>
      <b-card-sub-title class="font-weight-bold">Before you start, you'll need:</b-card-sub-title>
      <ol>
        <li>An <a href="https://help.instagram.com/502981923235522" target="_blank">Instagram Business Account</a> or <a href ="https://help.instagram.com/1158274571010880">Instagram Creator Account</a></li>
        <li>A Facebook Page connected to that account</li>
      </ol>
      <el-button :loading="loading.embed_signup" @click="launchWhatsAppSignup()" style="background-color: #1877f2; border: 0; border-radius: 4px; color: #fff; cursor: pointer; font-size: 16px; font-weight: bold; height: 40px; padding: 0 24px;">Log in with Facebook</el-button>
    </template>
  </b-card-body>
</template>
<script>
import { AsYouType } from 'libphonenumber-js';
// import CountryFlag from 'vue-country-flag';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  components: {
    // CountryFlag,
  },
  name: 'AddInstagramChannel',
  data() {
    return {
      provider: 'client_premise',
      hasRegisteredAccount: null,
      whatsappProvider: [
        // { text: 'Makna.ai', value: 'makna' },
        // { text: 'DAMCorp', value: 'damcorp' },
        { text: 'On Premise', value: 'client_premise' },
        { text: 'Cloud API', value: 'cloud_api_wa' },
      ],
      hasRegistered: [
        { text: 'Yes, I have one', value: true },
        { text: 'No', value: false },
      ],
      configuration: {
        username: 'admin',
        password: '',
        base_url: '',
        token: '',
        business_id: '',
        phone_number_id: '',
      },
      loading: {
        embed_signup: false,
      },
      choose_phone_number: false,
      phone_number_list: [],
    };
  },
  methods: {
    getCountryCode(input) {
      const asYouType = new AsYouType();
      asYouType.input(input);
      return asYouType.getNumber().country.toLowerCase();
    },
    createChannel() {
      this.$emit('createChannel', this.configuration, this.provider);
    },
    /* eslint-disable no-undef */
    async launchWhatsAppSignup() {
      try {
        this.loading.embed_signup = true;
        FB.login((response) => {
          if (response.authResponse) {
            console.log(response);
            // Use this token to call the debug_token API and get the shared WABA's ID
            const { accessToken } = response.authResponse;
            channelsAPI.initLinkAgeInstagram({
              token: accessToken,
            }).then(async (response1) => {
              await popupErrorMessages(response1);
              this.configuration = response1.data;
              this.provider = 'instagram_direct';
              this.createChannel();
              // const { data } = await channelsAPI.getEmbedPhoneNumber();
              // this.phone_number_list = data.list;
              // this.choose_phone_number = true;
              // this.provider = 'cloud_api_embed';
            });
          } else {
            console.log('User cancelled login or did not fully authorize.', response);
          }
          this.loading.embed_signup = false;
        }, {
          scope: 'instagram_manage_messages,instagram_basic,pages_manage_metadata,pages_show_list,pages_read_engagement',
          // scope: 'instagram_basic,instagram_manage_messages',
          extras: {
            // feature: 'whatsapp_embedded_signup',
            setup: {
              channel: 'IG_API_ONBOARDING',
            },
          },
        });
      } catch (error) {
        console.log(error);
        this.loading.embed_signup = false;
      }
    },
  },
};
</script>
